#epg-print-title{
  display: none;
}

@media only screen and (max-width: 740px){
  #root{
    height: 100% !important;
  }
}

/* Small screen fixes for EPG page only */
@media only screen and (max-width: 767px){
  #root,
  #root > :first-child,
  #root > div > div[data-value='cover'] > :first-child,
  #epg-queue{
    height: 100% !important;
  }

  #root > div > div[data-value='cover'],
  #root > div > div[data-value='cover'] > div > div[data-value='cover'],
  #epg-cover,
  #epg-queue-cover{
    height: 100%;
    overflow-y: auto;
  }

  #assemble-grid-multi-day{
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr !important;
  }
}

@media print{
  body{
    margin: 0;
    padding: 0;
    background: white !important;
  }

  body *{
    visibility: hidden;
  }

  #epg-box{
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(0) translateY(0);
    overflow: unset !important;
    padding-left: 0;
    padding-right: 0;
    border-color: white !important;
    background: white !important;
  }

  #epg-box h6,
  #epg-box h5{
    font-size: 10px;
  }

  .template-test{
    margin-top: 0 !important;
  }

  span[id^='icon-'] *,
  .hide-print{
    visibility: hidden !important;
    display: none;
  }

  .print-margin{
    margin-top: -2px !important;
  }

  #dates-shown *{
    color: black !important;
  }

  #draft-published *{
    visibility: hidden !important;
  }

  #epg-box,
  #epg-box *,
  #epg,
  #epg-header,
  #epg-header *,
  #epg-datepicker,
  #epg-datepicker *,
  #epg *{
    visibility: visible;
  }

  #epg-print-title{
    display: block;
    margin-top: 8px;
  }

  div[data-value='cover']{
    overflow: unset !important;
  }

  #epg > div:nth-child(2){
    width: 1100px;
    display: block;
    margin-top: 50px;
  }

  #epg > div:nth-child(2) > div{
    float: left;
  }

  #epg-datepicker{
    width: 1100px;
  }

  #epg-datepicker > div > div:last-child,
  #epg-datepicker > div > div:first-child{
    display: none;
  }

  #epg-datepicker > div > div:nth-child(2){
    z-index: 1;
    margin: 0 auto;
  }

  #epg-box > div > div:first-child > div > div:not(:nth-child(4)){
    display: none;
  }

  #epg-box > div > div:first-child > div > div:nth-child(4){
    margin-bottom: 20px;
  }

  #epg > div:nth-child(2) > div:nth-child(2) > div > div:nth-child(n){
    width: 140px;
    min-width: unset !important;
  }
}
